.slick-slider {
  margin-bottom: 10px;
}

.slick-slider.thumbnail {
  margin-left: 25px;
  margin-right: 25px;
}

.slick-dots li button:before {
  font-size: 10px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
  color: black;
  opacity: 0.25;
}

@media (min-width: 568px) {
  .slick-prev:before,
  .slick-next:before {
    display: block;
  }
}

.thumbnail img {
  padding: 0 5px;
}
